
import styled from 'styled-components'

const Styling = styled.div`
display: flex;
flex-wrap: wrap;
padding:2rem;

    @media only screen and (min-width: 768px) {
    padding:4rem;
    }


    .main_title{
    text-transform:uppercase;
    letter-spacing: -0.06em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    font-size:3rem;

        @media only screen and (min-width: 1024px) {
        margin-bottom: 4rem;
        font-size:clamp(2rem, 3vw, 6rem);
        }

        svg{
        width:0.7em;
        height:0.7em;
        margin-right: 1rem;
        }

    }

    .plugin-wrapper{
    width: 100%;
    position:relative;

        a{
        text-decoration:none;
        width: 100%;
        color:inherit;
        

            .info{
            position:absolute;
            bottom:4rem;
            left:4rem;
            color:var(--white);
            z-index: 20;

                .title{
                margin-bottom: 2rem;
                text-transform:uppercase;
                font-size:clamp(3rem, 3vw, 9rem);
                ${({theme}) => theme.tomato('regular')}  
                }

            }

            .gatsby-image-wrapper{
            width: 100%;
            height: 0;
            padding-bottom: 100%;
                
              @media only screen and (min-width: 1024px) {
                height:60vh;
                padding-bottom: 0;
              }

                img{
                ${({theme}) => theme.easing('0.5')}  
                }

                &:before{
                width:100%;
                height:100%;
                position: absolute;
                top:0;
                left:0;
                background:linear-gradient(to bottom,rgba(0,0,0,0.4),rgba(0,0,0,1));
                content:"";
                z-index: 10;
                mix-blend-mode: overlay;
                transition: 0.5s all ease-in-out;
                opacity: 0.7;
                }

            }

            &:hover{
                .gatsby-image-wrapper{
                    img{
                    transform:scale(1.1);
                    }

                    &:before{
                        opacity: 1;
                    }
                }
            }
        }
    }
`

export { Styling }