import React from "react";
import { Styling } from "./styled";
import ArrowRight from "../../../images/arrow_right_black.inline.svg";
import { Link } from "gatsby";
import { BsArrowRightShort, BsArrowLeftShort } from "react-icons/bs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function PluginsSection({ plugin, isTemplate }) {
  //console.log(plugin);
  let theImage;
  {
    isTemplate
      ? (theImage = getImage(
          plugin.acf_product_info.templates.pluginArchiveImage.localFile
        ))
      : (theImage = getImage(
          plugin.acf_product_info.plugins.pluginArchiveImage.localFile
        ));
  }

  return (
    <Styling>
      <h2 className="main_title">
        <ArrowRight /> {isTemplate ? "Templates" : "Plugins"}
      </h2>
      <div className="plugin-wrapper">
        <Link to={`/shop${isTemplate ? "/" + plugin.slug : plugin?.link}`}>
          <div className="info">
            <div className="title">{plugin?.title}</div>
            <div className="price">
              £
              {isTemplate
                ? plugin?.acf_product_info?.templates?.price.toFixed(2)
                : plugin?.acf_product_info?.plugins?.price.toFixed(2)}
            </div>
          </div>
          <GatsbyImage image={theImage} className="image" alt={"test"} />
        </Link>
      </div>
    </Styling>
  );
}
