import * as React from "react";
import { graphql } from "gatsby";
import { Styling } from "../../styles/pages/shop.styled";
import ArrowDown from "../../images/arrow_down.inline.svg";
import ProductSlider from "../../components/reusable/ProductSlider";
import PluginsSection from "../../components/reusable/PluginsSection";
import Seo from "../../components/global/seo";

// markup
const ShopPage = ({ data }) => {
  return (
    <Styling>
      <Seo title={data.shop.seo.title} description={data.shop.seo.metaDesc} />
      <div className="intro">
        <h1 className="main_title">
          Shop <ArrowDown />
        </h1>
        <div className="intro_text">{data.shop.shop_archive.introText}</div>
      </div>
      <ProductSlider
        products={data.samplePacks.nodes}
        title="Sample Packs"
        link="/shop/sample-packs/"
        hasTitle={true}
        hasArrows={true}
        hasViewAll={true}
        showProgress={false}
      />
      <PluginsSection plugin={data.plugin} />
      <ProductSlider
        products={data.tutorials.nodes}
        title="Tutorials"
        link="/programmes/on-demand/"
        hasTitle={true}
        hasArrows={true}
        hasViewAll={true}
        showProgress={false}
      />
      {/* <PluginsSection plugin={data.template} isTemplate={true} /> */}
      <ProductSlider
        products={data.merch.nodes}
        title="Merch"
        link="/shop/merch/"
        hasTitle={true}
        hasArrows={true}
        hasViewAll={true}
        showProgress={false}
      />
    </Styling>
  );
};

export default ShopPage;

export const query = graphql`
  {
    shop: wpPage(title: { eq: "Shop" }) {
      shop_archive {
        introText
      }
      seo {
        metaDesc
        title
      }
    }
    plugin: wpProduct(title: { eq: "Infinite" }) {
      id
      title
      link
      acf_product_info {
        plugins {
          pluginArchiveImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1500
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
          price
        }
      }
    }
    template: wpProduct(title: { eq: "Test template" }) {
      id
      title
      link
      slug
      acf_product_info {
        templates {
          pluginArchiveImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1500
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
          price
        }
      }
    }
    samplePacks: allWpProduct(
      filter: {
        productCategories: {
          nodes: { elemMatch: { name: { eq: "Sample Packs" } } }
        }
      }
    ) {
      nodes {
        id
        link
        slug
        acf_product_info {
          samplePacks {
            price
            images {
              fieldGroupName
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 1500
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
          }
        }
        title
      }
    }
    merch: allWpProduct(
      filter: {
        productCategories: { nodes: { elemMatch: { name: { eq: "Merch" } } } }
      }
    ) {
      nodes {
        id
        link
        slug
        acf_product_info {
          merch {
            price
            images {
              fieldGroupName
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 1500
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
          }
        }
        title
      }
    }

    tutorials: allWpCourse {
      nodes {
        title
        courseBuilder {
          courseThumbnailImageSquare {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1500
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
          price
        }
        slug
      }
    }

    allWpProduct {
      products: nodes {
        title
        id
        slug
        link
      }
    }
  }
`;
