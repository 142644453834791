import React, {useRef, useEffect} from 'react'
import { ProductSliderWrapperStyles } from './ProductSliderWrapperStyles.js'
import ProductCard from '../../reusable/ProductCard'
import ArrowRight from '../../../images/arrow_right_black.inline.svg'
import { Link } from 'gatsby';
import { Swiper, SwiperSlide } from 'swiper/react';
import {BsArrowRightShort, BsArrowLeftShort}from 'react-icons/bs'
import gsap from 'gsap'
import "swiper/css";
import "swiper/css/pagination"

import SwiperCore, {
    Autoplay,Pagination,Navigation
} from 'swiper/core';
  
// install Swiper modules
SwiperCore.use([Autoplay,Pagination,Navigation]);
  
  

export default function ProductSlider({
    products, 
    hasTitle, 
    title,
    link,
    hasArrows, 
    hasViewAll, 
    showProgress
}) {
    const updateProgressBar = (progress) => {
        gsap.to('.progressIndicator', {width:progress * 100+'%'})
        
    } 
    const prevRef = useRef()
    const nextRef = useRef()

    const targetRef = useRef()
    const newPositionRef = useRef()
    const originalPositionRef = useRef()

    const repositionElement = () => {
        if(typeof window !== undefined){
            const target = targetRef.current
            const newPosition = newPositionRef.current
            const originalPosition = originalPositionRef.current

            if(window.innerWidth < 550 ){
                newPosition.parentNode.insertBefore(target, newPosition)
            }else{
                originalPosition.parentNode.insertBefore(target, originalPosition)
            }
        }
    }

    useEffect(()=> {
        repositionElement()

        if(typeof window !== undefined){
            window.addEventListener('resize', repositionElement)    
        }
       return () => {
            window.removeEventListener('resize', repositionElement)    
        }
    },[])
    
    return (
        <ProductSliderWrapperStyles className={showProgress === false ? 'product_slider' : "product_slider"}>
            <div className={hasTitle || hasViewAll || hasArrows ? `slider_intro margin-bottom` : `slider_intro`}>
                {
                    hasTitle === true ? 
                        <h2 className="main_title"><ArrowRight /> {title}</h2>
                    : ""
                }
                <div className="buttons_wrapper" >
                    {
                        hasViewAll === true ?
                            <Link to={link} ref={targetRef} className="capsule_button white viewAll">View all {title}</Link>
                        : <div ref={targetRef} />
                    }
                    <div ref={originalPositionRef}></div>
                    {
                        hasArrows === true ?
                            <div className="arrows">
                                <button ref={prevRef} className="capsule_button white prev" aria-label="Previous"><BsArrowLeftShort /></button>
                                <button ref={nextRef} className="capsule_button white next" aria-label="Next"><BsArrowRightShort /></button>
                            </div>
                        : ""
                    }
                </div>
            </div>

            <Swiper
            spaceBetween={20}
            slidesPerView={1.25}
            breakpoints={{
                "640": {
                  "slidesPerView": 2.5,
                  "spaceBetween":typeof window !== 'undefined' ? window.innerWidth / 100 * 2 : '',
                },
                "1024": {
                  "slidesPerView": 3.5,
                }
              }}
            navigation={{
                prevEl: hasArrows === true ? prevRef.current : '',
                nextEl: hasArrows === true ? nextRef.current : '',
            }}
            onInit={(swiper) => {
                swiper.params.navigation.prevEl = prevRef.current;
                swiper.params.navigation.nextEl = nextRef.current;
                swiper.navigation.init();
                swiper.navigation.update();
              }}
            onSlideChange={(swiper) => updateProgressBar(swiper.progress)}
            autoplay={{
                "delay": 2500,
                "disableOnInteraction": true
                }}
            >
            {
                products.map((product, index) => {
                  
                    return(
                        <SwiperSlide>
                            <ProductCard 
                            key={`product_${index}`} 
                            product={product} 
                            smallText={
                                product?.product?.productCategories?.nodes[0].name
                            } />
                        </SwiperSlide>
                    )
                })
            }
            </Swiper>

            {showProgress === true ?    
                <div className="progressBarWrapper">
                    <div className="progressBar">
                        <div className="progressIndicator"></div>
                    </div>
                </div>
            : ""
            }
            <div className="buttonMobile">
                <div ref={newPositionRef}></div>
            </div>
        </ProductSliderWrapperStyles>
    )
}
