
import styled from 'styled-components'

const ProductSliderWrapperStyles = styled.div`
width: 100%;
overflow-x: hidden;
/* border-bottom:1px solid var(--grey_light); */

    @media only screen and (min-width: 500px) {
        padding-bottom: 4rem;
    }

    @media only screen and (min-width: 1024px) {
        padding-bottom: 0;
    }

    &:last-child{
    margin-bottom: 0;
    }


    &.add_bottom_padding{
    padding-bottom: 8rem;
    }

    .buttonMobile{
    padding-left:2rem;
    width: calc(100% - 2rem);
    
        .capsule_button{
        width: 100%;
        justify-content: center;
        }

    }

    .slider_intro{
    display:flex;
    justify-content: space-between;
    width:100%;
    padding:0 4rem;
    align-items: center;

        &.margin-bottom{
        margin-bottom:2rem;
            @media only screen and (min-width: 1024px) {
                margin-bottom:4rem;
            }
        }

        .buttons_wrapper{
        display: flex;
        
            a,
            button{
            margin-left:1rem;
            }

            button:first-child{
            margin-left:4rem;
            }

            .arrows{
            display: none;

                @media only screen and (min-width: 1024px) {
                display: block;
                }

            }
        }

        .main_title{
        text-transform:uppercase;
        letter-spacing: -0.06em;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size:3rem;

            @media only screen and (min-width: 1024px) {
            font-size:clamp(2rem, 3vw, 6rem);
            }


            svg{
            width:0.7em;
            height:0.7em;
            margin-right: 1rem;
            }

        }

    }

    


    .swiper-container{
    padding:0 2rem;
    overflow:visible ;

        @media only screen and (min-width: 640px) {
            padding:0 2vw;
        }
    
        &:last-child{
            padding-bottom: 8rem;
        }

        .swiper-slide{
        display: flex;
        }
    }

    &.padding-left{
        
        .swiper-container{
        padding:0 2vw 8rem 10vw;
        }

        

    }

    .progressBarWrapper{
    width: 100%;
    transform:translateX(5vw);
    margin:4rem 0;

        @media only screen and (min-width: 1024px) {
            margin:8rem 0;
        }

        .progressBar{
        width: 280px;
        height:0.25rem;
        margin:0 auto;
        background:rgba(0,0,0,.3);
        position: relative;

            .progressIndicator{
            position: absolute;
            left:0;
            top:0;
            height:100%;
            width:0%;
            background:var(--black);
            }

        }

    }
`

export { ProductSliderWrapperStyles }