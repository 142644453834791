import styled from 'styled-components'

const Styling = styled.div`
background:var(--white);
padding:0 0 4rem 0;

@media only screen and (min-width: 1024px) {
padding:0 0 8rem 0;
}

    

    .intro{
    position: relative;
     padding:10rem 2rem 4rem 2rem;

        @media only screen and (min-width: 350px) {
        padding:10rem 4rem 4rem 4rem;
        }
 
        @media only screen and (min-width: 1024px) {
        padding:16rem 8rem 10rem 8rem
        }

    
        .main_title{
        ${({theme}) => theme.tomato('regular')}  
        text-transform:uppercase;
        letter-spacing: -0.15rem;
        position: relative;
        width: auto;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-size:3rem;
        line-height:2rem;
        margin-bottom:2rem;

            @media only screen and (min-width: 350px) {
            font-size:3.5rem;
            line-height:3rem;
            letter-spacing: -0.2rem;        
            }

            @media only screen and (min-width: 420px) {
            font-size:4rem;
            line-height:3rem;
            letter-spacing: -0.3rem;
            }
            
            @media only screen and (min-width: 610px) {
            font-size:5rem;
            line-height:4rem;
            letter-spacing: -0.4rem;
            }

            @media only screen and (min-width: 768px) {
            margin-bottom: 4rem;
            }
            
            @media only screen and (min-width: 800px) {
            font-size:6rem;
            line-height:5rem;
            }

            @media only screen and (min-width: 1024px) {
            font-size:clamp(6rem, 8vw, 12rem);
            line-height: clamp(6rem/1.15, 8vw/1.15, 12rem/1.15);
            margin-bottom: clamp(6rem/2, 6vw/2, 20rem/2);
            }
        
            span{
            width: 100%;
            align-items: center;
            display: flex;
            }

            svg{
            width: 0.7em;
            height:0.7em;
            margin-left:1rem;

                path{
                fill:var(--black);
                }

            }
        }

        .intro_text{
        line-height:2rem;
        font-size:1.5rem;
            
            @media only screen and (min-width: 768px) {
                margin-bottom: clamp(6rem/2, 6vw/2, 20rem/2);
                line-height:2.5rem;
                font-size:1.6rem;
                max-width: 60%;
            }
        }
    }

    .product_slider{
    border-top:1px solid var(--grey_light);
    padding-top:2rem;

        @media only screen and (min-width: 1024px) {
        padding-top:8rem;
        }
    }
`

export { Styling }